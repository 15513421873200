

/* .screenplayEditor > p {
  padding-top: 0px;
  margin-top: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 100%;
} */

.center {
  text-align: center;
  text-transform: uppercase;
}

.synopses {
  font-family: DroidSans, sans-serif;
  border-radius: 0.25em;
  padding: 0.25em;
  min-height: 1em;
  font-size: 0.9em;
}

.section {
  font-family: DroidSans, sans-serif;
  font-weight: normal;
  text-decoration: underline;
  min-height: 1em;
  padding-bottom: 0.25em;
}

.section[section-level="1"] {
  font-weight: bold;
  text-decoration: underline;
  font-style: normal;
  font-size: 1.5em;
  min-height: 1.5em;
}

.section[section-level="2"] {
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  font-size: 1.5em;
  min-height: 1.5em;
}

.section[section-level="3"] {
  font-weight: normal;
  text-decoration: none;
  font-style: italic;
  font-size: 1.5em;
  min-height: 1.5em;
}

.section[section-level="4"] {
  font-weight: bold;
  text-decoration: underline;
  font-style: normal;
  font-size: 1.25em;
  min-height: 1.25em;
}

.section[section-level="5"] {
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  font-size: 1.25em;
  min-height: 1.25em;
}

.section[section-level="6"] {
  font-weight: normal;
  text-decoration: none;
  font-style: italic;
  font-size: 1.25em;
  min-height: 1.25em;
}

.section[section-level="7"] {
  font-weight: bold;
  text-decoration: underline;
  font-style: normal;
  font-size: 1em;
  min-height: 1.25em;
}

.section[section-level="8"] {
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  font-size: 1em;
  min-height: 1.25em;
}

.section[section-level="9"] {
  font-weight: normal;
  text-decoration: none;
  font-style: italic;
  font-size: 1em;
  min-height: 1.25em;
}

/** New Element Styles **/

@font-face {
  font-family: "Courier Prime";
  src: local("Courier Prime"),
    url(../fonts/Courier\ Prime.ttf) format("truetype");
}

@font-face {
  font-family: "Courier Prime";
  font-weight: bold;
  src: local("Courier Prime Bold"),
    url(../fonts/Courier\ Prime\ Bold.ttf) format("truetype");
}

.screenplayEditor {
  width: 100%;
  display: block;
  font: 12pt "Courier Prime", fixed;
  outline: 0em solid transparent;
  min-height: 1em;

  cursor: text;

  transition: all 500ms ease-in-out;
}


.action {
  padding: 0;
  padding-top: 1em;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 100%;
  min-height: 16px;
}


.sceneheader {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 100%;
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-weight: bold;
  text-transform: uppercase;
}

.character {
  margin-top: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 100%;


  padding-top: 1em;
  min-height: 16px;
  text-align: center;

  text-transform: uppercase;
}


.parenthetical {
  padding-top: 0px;
  margin-top: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 100%;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  min-width: 3em;
  text-align: center;
  min-height: 16px;
}

.dialogue {
  padding-top: 0px;
  margin-top: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 100%;

  min-height: 16px;
  max-width: 25em;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

/* special case: dialogue followed by a parenthetical; the extra line needs to be suppressed */
.dialogue + .parenthetical {
  padding-bottom: 0;
}

.transition {
  margin-top: 0;
  margin-bottom: 0px;
  line-height: 100%;

  padding-top: 1em;
  text-align: right;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  min-height: 16px;
}

.parenthetical br {
  display: none;
}

.parenthetical:before {
  content: "(";
  margin-left: -0.5em;
}

.parenthetical:after {
  content: ")";
}

.collapse {
  margin-left: -0.75em;
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-top: -0.1em;
  cursor: pointer;
  user-select: none;
}